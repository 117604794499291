import { Box, Divider, Flex, Heading, Text } from "@chakra-ui/react";
import { graphql } from "gatsby";
import * as React from "react";
import { ArticleCard, Layout, Seo } from "../components";
import "../styles/screen.scss";

const seoBundle = {
  title: "Boise Steel Erectors",
  subtitle:
    "Helpful Articles Covering All Aspects of Steel Construction in Boise, Idaho",
  description:
    "Having served Boise and the surrounding Treasure Valley for over several decades, here are some helpful articles to answer questions mamy customers have",
  slug: "/articles-landing",
};

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      gatsbyImageData(
        height: 400
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
`;

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "feusa.jpg" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "storage.jpg" }) {
      ...fluidImage
    }
    imageThree: file(relativePath: { eq: "background.png" }) {
      ...fluidImage
    }
  }
`;

const Articles = ({ location, data }) => {
  const articles = [
    {
      title:
        "Pitfalls to avoid when requesting quotes for a steel building project",
      description: `Embarking on a new steel building project is an exciting venture, whether it's for a spacious garage, a new workshop, or a storage facility. However, the path to your perfect steel structure is laden with decisions, and one crucial step stands out: getting an accurate quote.`,
      image: data.imageThree,
      alt: "brand new steel construction",
      href: "/articles/pitfalls-to-avoid-when-pricing-a-steel-building",
    },
    {
      title: "The Process of Steel Construction in Boise, Idaho",
      description:
        "It's a complex process that requires skill, precision, and a whole lot of coordination, but the end result? Absolutely worth it.",
      image: data.imageTwo,
      alt: "Steel constructed storage facility in Idaho",
      href: "/articles/steel-erectors-boise",
    },
    {
      title: "Steel Construction in the Northwest",
      description: `The steel erection construction industry has become a vital
              component of the economic growth in the Northwest.`,
      image: data.imageOne,
      alt: "brand new steel construction",
      href: "/articles/northwest",
    },
  ];

  return (
    <Layout title={"Home"} location={location}>
      <Seo seoBundle={seoBundle} />
      <Flex
        maxW='1450px'
        h='100%'
        // header takes up 40/50 pixels
        pt={["150px", null, "150px"]}
        px={["20px", null, "100px"]}
        pb={["60px", null, "100px"]}
        align='center'
        justify='center'
        flexDirection='column'
        mx='auto'
      >
        <Heading
          as='h1'
          color='dkGrey'
          mb={["20px", null, null, "10px"]}
          mt='0px'
          fontWeight={800}
          fontSize={["3em", "4em"]}
          fontFamily='heading'
          lineHeight={["100%", null, null, "120%"]}
          textAlign='center'
        >
          Helpful Construction Articles from Boise Experts
        </Heading>
        <Heading
          as='h2'
          color='dkGrey'
          mb={["20px", null, null, "10px"]}
          mt='0px'
          fontWeight={800}
          fontSize={["1.5em", "2em"]}
          fontFamily='body'
          lineHeight={["120%"]}
          textAlign='center'
        >
          JMF Construction
        </Heading>
        <Text
          mt='20px'
          mb='7'
          textAlign='center'
          fontSize={20}
          maxW='800px'
          lineHeight='120%'
        >
          Having served Boise and the surrounding Treasure Valley for decades,
          we've been able to build a long lasting relationship with our
          customers -- and we never get over the satisfaction of delivering
          excellent service on every project.
        </Text>
        <Box mt='40px'>
          {articles.map((article) => {
            return <ArticleCard article={article} />;
          })}
        </Box>
        <Divider maxW='600px' my='40px' orientation='horizontal' />
        <Heading as='h3' textAlign='center'>
          JMF Construction
        </Heading>
        <Heading as='h4' fontFamily='body' fontSize='16px'>
          A Boise, Idaho Company
        </Heading>
        <Text
          mt='20px'
          mb='7'
          textAlign='center'
          fontSize={20}
          maxW='800px'
          lineHeight='120%'
        >
          We’ve done projects all over the Treasure Valley and beyond for
          businesses such as Simplot Western Stockmen’s, CSDI, Champion Produce
          in Parma, and Farm Equipment USA in Fruitland and Jerome. Have a small
          project? You’ve come to the right place. We build smaller projects
          regularly, not just the large scale ones.
        </Text>
        <Text
          mt='20px'
          mb='7'
          textAlign='center'
          fontSize={20}
          maxW='800px'
          lineHeight='120%'
        >
          Here at JMF, we make it our business to get to know you and your
          unique situation. Call us today!
        </Text>
        <Text
          mb='40px'
          mt='20px'
          fontSize={24}
          textAlign='center'
          maxW='800px'
          lineHeight='120%'
          fontWeight='bold'
          color='dkGrey'
        >
          Call us at <a href='tel:2088801062'>(208) 880-1062</a>
        </Text>
      </Flex>
    </Layout>
  );
};

export default Articles;
